<template>
  <v-dialog :value="isShow" :max-width="800" persistent no-click-animation>
    <v-card>
      <v-card-title id="sub-bar" class="text-center">
        <div id="lbl-screen-name">{{ $t("詳細情報") }}</div>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="detailPopup" lazy-validation>
          <v-container style="max-width: 600px; margin: 0 auto; padding: 0">
            <v-simple-table class="custom-table">
              <thead>
                <tr>
                  <th class="no-border">
                    <v-row style="font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 0.87)"
                      >品番/品名&nbsp;&nbsp;&nbsp;&nbsp;
                      <template v-if="$props.itemCd && $props.itemName">
                        {{ $props.itemCd }}/{{ $props.itemName }}
                      </template>
                    </v-row>
                    <v-row style="font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 0.87)"
                      >管理番号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ itemManageNo }}</v-row
                    >
                  </th>
                  <th class="no-border text-right pr-0">
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="closeDetailsInfo"
                    >
                      {{ $t("btn.btn_close") }}</v-btn
                    >
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="addDetailsInfo()"
                      :disabled="false"
                    >
                      {{ $t("btn.btn_insert") }}</v-btn
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="highlight-column text-left">
                    倉庫<span class="require asterisk-spacer">*</span>
                  </td>
                  <td class="text-left">
                    <c-warehouse-input
                      v-model="detailInfoDisp.warehouse"
                      class="pt-6 pb-4"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column text-left">
                    ロケーション<span class="require asterisk-spacer">*</span>
                  </td>
                  <td class="text-left">
                    <c-location-input
                      v-model="detailInfoDisp.locationSid"
                      :warehouseSid="detailInfoDisp.warehouse"
                      class="pt-6 pb-4"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">
                    納品種別<span class="require asterisk-spacer">*</span>
                  </td>
                  <td class="text-left">
                    <c-code-input
                      v-model="detailInfoDisp.deliveryType"
                      :codeType="deliveryTypeDev"
                      class="pt-6 pb-4"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">グループ名</td>
                  <td class="text-left">
                    <v-text-field
                      class="txt-single pt-2"
                      v-model="detailInfoDisp.groupName"
                      :rules="[rules.isStrLen(detailInfoDisp.groupName, 200)]"
                      dense
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">保管期限</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-text-field
                        dense
                        v-model="detailInfoDisp.retentionDuedate"
                        class="txt-single date-style pt-2"
                        :rules="[]"
                        clear-icon="mdi-close-circle"
                        clearable
                        maxlength="10"
                        @change="changeDate"
                      ></v-text-field>
                      <v-menu
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="-100"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        class="date-calendar"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" text x-small>
                            <v-icon>mdi-calendar</v-icon>
                          </v-btn>
                        </template>
                        <v-date-picker
                          v-model="retentionDuedateCal"
                          @input="dateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">{{ $t("label.lbl_price") }}</td>
                  <td class="text-center" style="vertical-align: middle">
                    <v-text-field
                      class="txt-single date-style pt-2 mx-auto right-align-input"
                      dense
                      v-model="detailInfoDisp.amount"
                      :rules="[]"
                      clear-icon="mdi-close-circle"
                      clearable
                      maxlength="12"
                      @change="parseNumberFormat(detailInfoDisp.amount)"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">通貨単位</td>
                  <td class="text-left">
                    <c-code-input
                      v-model="detailInfoDisp.currencyUnits"
                      :codeType="monetaryUnitDiv"
                      class="pt-6 pb-4"
                      maxlength="3"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">コントロールナンバー</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-text-field
                        dense
                        v-model="detailInfoDisp.controlNumber"
                        class="txt-single pt-2"
                        :rules="[rules.isStrLen(detailInfoDisp.controlNumber, 200)]"
                        clear-icon="mdi-close-circle"
                        clearable
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">仕様通知ナンバー</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-text-field
                        dense
                        v-model="detailInfoDisp.specificationNoticeNumber"
                        class="txt-single pt-2"
                        :rules="[rules.isStrLen(detailInfoDisp.specificationNoticeNumber, 200)]"
                        clear-icon="mdi-close-circle"
                        clearable
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">
                    部品要求者<span class="require asterisk-spacer">*</span>
                  </td>
                  <td class="text-left">
                    <c-user-input
                      :label="$t('部品要求者')"
                      v-model="detailInfoDisp.partsRequester"
                      class="pt-6 pb-4"
                      required
                      maxlength="200"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="highlight-column">備考</td>
                  <td>
                    <div class="d-flex align-center">
                      <v-textarea
                        class="txt-single pt-2"
                        v-model="detailInfoDisp.remarks"
                        outlined
                        no-resize
                        rows="4"
                        :rules="[rules.isStrLen(detailInfoDisp.remarks, 200)]"
                      ></v-textarea>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- 登録ボタンを押下時出現ダイアログ -->
            <v-dialog v-model="updateDialogMessage" :max-width="800">
              <v-card>
                <v-card-title class="blue-grey lighten-3" primary-title>
                  {{ $t("btn.btn_ok") }}
                </v-card-title>
                <v-card-text class="pa-4">
                  <p>{{ backMessage }}</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="updateOks"> {{ $t("btn.btn_ok_en") }} </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- 登録ボタンを押下時出現ダイアログ(割り込み) -->
            <v-dialog v-model="updateExclutionDialogMessage" :max-width="800">
              <v-card>
                <v-card-title class="blue-grey lighten-3" primary-title>
                  {{ $t("btn.btn_ok") }}
                </v-card-title>
                <v-card-text class="pa-4">
                  <p>{{ updateExclutionMessage }}</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="updateExclutionOk"> {{ $t("btn.btn_force_interrupt") }} </v-btn>
                  <v-btn @click="updateExclutionNg"> {{ $t("btn.btn_cancel") }} </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { commonUtil } from "@/assets/scripts/js/CommonUtil";
import commonRules from "@/mixins/CommonRules";
import commonMixin from "@/mixins/CommonMixin";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";

export default {
  // 表示名
  name: "DetailInfoDialogForStockParts",
  // コンポーネント
  mixins: [commonMixin, commonRules],
  // プロパティ
  props: {
    itemStandardSid: { type: String, Required: true },
    clientSidForDetailInfo: { type: String, Required: true },
    itemCd: { type: String, Required: false },
    itemName: { type: String, Required: false },
    itemManageNo: { type: String, Required: false },
    warehouse: { type: String, Required: false },
    locationSid: { type: String, Required: false },
    locationNo: { type: String, Required: false },
    deliveryType: { type: String, Required: false },
    groupName: { type: String, Required: false },
    retentionDuedate: { Date: String, Required: false },
    amount: { type: String, Required: false },
    currencyUnits: { type: String, Required: false },
    controlNumber: { type: String, Required: false },
    specificationNoticeNumber: { type: String, Required: false },
    partsRequester: { type: String, Required: false },
    partsRequesterUserId: { type: String, Required: false },
    remarks: { type: String, Required: false },
    stocks: { type: String, Required: false },
    stockInfoUpdateDateTime: { type: String, Required: false },
    isShow: { type: Boolean, Required: true },
    isExclutionFlg: { type: Boolean, Required: true },
    clientSidForExclutionLockInfo: { type: String, Required: true },
    item11: { type: String, Required: true },
    item14: { type: String, Required: true },
    item15: { type: String, Required: true },
    lockedUserName: { type: String, Required: false },
    updateDatetime: { type: String, Required: true },
  },
  data: () => {
    return {
      deliveryTypeDev: appConfig.CODETYPE.DELIVERY_TYPE_DIV,
      monetaryUnitDiv: appConfig.CODETYPE.MONETARY_UNIT_DIV,
      dateMenu: false,
      formattedAmount: "",
      retentionDuedateCal: "",
      detailInfo: {},
      detailInfoDisp: {},
      exclutionLockInfo: {},
      lockProcessingDiv: "",
      processingDiv: "",
      lockProcessingCheck: "",
      // 登録ボタンダイアログ
      updateDialogMessage: false,
      // 登録（割り込み）ボタンダイアログ
      updateExclutionDialogMessage: false,
      // 登録（割り込み）OKフラグ
      updateExclutionFlg: false,
      // 登録ボタンダイアログメッセージ
      backMessage: "",
      // 登録（割り込み）ボタンダイアログメッセージ
      updateExclutionMessage: "",
    };
  },
  methods: {
    init() {
      this.detailInfo = {};
      this.detailInfoDisp = {};
      this.detailInfo = {
        itemCd: this.itemCd ?? "",
        warehouse: this.warehouse ?? "",
        locationSid: this.locationSid ?? "",
        locationNo: this.locationNo ?? "",
        groupName: this.groupName ?? "",
        deliveryType: this.deliveryType ?? "",
        retentionDuedate:
          this.retentionDuedate == ""
            ? ""
            : dateTimeHelper.convertUTC2JST(this.retentionDuedate).split(" ")[0],
        amount: this.amount ?? "",
        currencyUnits: this.currencyUnits ?? "",
        controlNumber: this.controlNumber ?? "",
        specificationNoticeNumber: this.specificationNoticeNumber ?? "",
        partsRequester: this.partsRequester ?? "",
        partsRequesterUserId: this.partsRequesterUserId ?? "",
        partsRequesterUserName: this.partsRequesterUserName ?? "",
        stockInfoUpdateDateTime: this.stockInfoUpdateDateTime ?? "",
        remarks: this.remarks ?? "",
        stocks: this.stocks ?? "",
      };
      this.exclutionLockInfo = {
        clientSid: this.clientSidForExclutionLockInfo ?? "",
        procDiv: this.procDiv ?? "",
        item11: this.item11 ?? "",
        item14: this.item14 ?? "",
        item15: this.item15 ?? "",
        updateDatetime: this.updateDatetime ?? "",
        isExclutionFlg: this.isExclutionFlg ?? "",
        lockedUserName: this.lockedUserName ?? "",
      };
      this.detailInfoDisp = Object.assign({}, this.detailInfo);
      this.detailInfoDisp.retentionDuedate = this.formatDate(
        this.detailInfo.retentionDuedate
      )?.substr(0, 10);
      this.parseNumberFormat(this.detailInfo.amount);
    },
    closeDetailsInfo() {
      this.detailInfo = {};
      this.detaiInfolDisp = {};
      this.$emit("closeDetailsInfo", {
        exclutionLockInfo: this.exclutionLockInfo,
      });
      this.exclutionLockInfo = {};
      this.$emit("update:isShow", false);
    },
    addDetailsInfo() {
      var updateFlg = true;
      if (
        ((this.detailInfo.amount == "" || this.detailInfo.amount == null) &&
          this.detailInfoDisp.amount == "") ||
        this.detailInfoDisp.amount == null
      ) {
        this.detailInfoDisp.amount = "";
      } else if (this.detailInfoDisp.amount != "" || this.detailInfoDisp.amount != null) {
        let enInclude = this.detailInfoDisp.amount.includes(",");
        if (enInclude) {
          this.detailInfoDisp.amount = this.detailInfoDisp.amount.replaceAll(",", "");
        }
      }
      if (
        this.detailInfoDisp.retentionDuedate == null ||
        this.detailInfoDisp.retentionDuedate == ""
      ) {
        this.detailInfoDisp.retentionDuedate = "";
      } else {
        this.detailInfoDisp.retentionDuedate = dateTimeHelper.convertUTC(
          this.detailInfoDisp.retentionDuedate
        );
      }
      this.determineReturnValue(this.compareItems(this.detailInfoDisp, this.detailInfo));
      if (this.lockProcessingCheck == "00") {
        this.updateDialog();
        updateFlg = false;
      }
      if (updateFlg) {
        if (this.exclutionLockInfo.isExclutionFlg) {
          this.updateExclutionDialog();
        } else {
          this.updateExclutionFlg = true;
          // this.detailInfoDisp.stockInfoUpdateDateTime = dateTimeHelper.convertJST(
          //   this.detailInfoDisp.stockInfoUpdateDateTime
          // );
          this.exclutionLockInfo.procDiv = "02";
          this.$emit("addDetailsInfo", {
            itemCd: this.detailInfo.itemCd,
            beforeLocationSid: this.detailInfo.locationSid,
            afterLocationSid: this.detailInfoDisp.locationSid,
            lockProcessingDiv: "01",
            procCd: "200",
            processingDiv: this.processingDiv,
            exclution: this.exclutionLockInfo,
            detailInfoUpdateData: this.detailInfoDisp,
            itemStandardSid: this.itemStandardSid,
            itemManageNo: this.itemManageNo,
            stock: this.stocks,
          });
          this.updateExclutionDialogMessage = false;
          this.$emit("update:isShow", false);
        }
      }
    },
    compareItems(list1, list2) {
      const differences = [];
      Object.keys(list1).forEach((key) => {
        if (list1[key] !== list2[key]) {
          differences.push(key);
        }
      });
      return differences;
    },
    determineReturnValue(keys) {
      const locationKey = "locationSid";
      const opeHistSubKey = [
        "partsRequester",
        "groupName",
        "retentionDuedate",
        "deliveryType",
        "amount",
        "specificationNoticeNumber",
        "currencyUnits",
        "remarks",
      ];
      const hasLocation = keys.includes(locationKey);
      const hasOpetHistSub = keys.some((key) => opeHistSubKey.includes(key));

      if (hasLocation && hasOpetHistSub) {
        this.lockProcessingCheck = "03";
        this.processingDiv = "03";
      } else if (hasLocation) {
        this.lockProcessingCheck = "01";
        this.processingDiv = "01";
      } else if (hasOpetHistSub) {
        this.lockProcessingCheck = "02";
        this.processingDiv = "02";
      } else {
        this.lockProcessingCheck = "00";
      }
    },
    // 登録ボタン押下
    // 変更がない場合、
    updateDialog() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-006_001_E");
    },
    // 登録ボタンダイアログ：OK押下
    updateOks() {
      this.updateDialogMessage = false;
    },
    updateExclutionDialog() {
      this.updateExclutionDialogMessage = true;
      this.updateExclutionMessage =
        this.lockedUserName +
        "が作業中です。" +
        this.lockedUserName +
        "に割り込み処理を行って良いか確認してください。";
    },
    // 登録（割り込み）ボタンダイアログ：OK押下
    updateExclutionOk() {
      this.updateExclutionFlg = true;
      // this.detailInfoDisp.stockInfoUpdateDateTime = dateTimeHelper.convertJST(
      //   this.detailInfoDisp.stockInfoUpdateDateTime
      // );
      this.exclutionLockInfo.procDiv = "02";
      this.$emit("addDetailsInfo", {
        itemCd: this.detailInfo.itemCd,
        beforeLocationSid: this.detailInfo.locationSid,
        afterLocationSid: this.detailInfoDisp.locationSid,
        lockProcessingDiv: "02",
        procCd: "200",
        processingDiv: this.processingDiv,
        exclution: this.exclutionLockInfo,
        detailInfoUpdateData: this.detailInfoDisp,
        itemStandardSid: this.itemStandardSid,
        itemManageNo: this.itemManageNo,
        stock: this.stocks,
      });
      this.updateExclutionDialogMessage = false;
      this.$emit("update:isShow", false);
    },
    // 登録（割り込み）ボタンダイアログ：NG押下
    updateExclutionNg() {
      this.updateExclutionDialogMessage = false;
    },
    // yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * カンマ付きの数字に変換する
     * @param {*} value 変換対象の値
     */
    parseNumberFormat(value) {
      if (!value) return 0;
      value = commonUtil.zen2han(value);
      let enInclude = value.includes(",");
      if (enInclude) {
        value = value.replaceAll(",", "");
      }
      value = String(value);
      let delimiter = ",";
      let len = value.length;
      let delimiterPoint = 3;
      if (len <= delimiterPoint) {
        this.detailInfoDisp.amount = value;
      }
      let numOfDeliimiter =
        len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

      for (let i = 1; i <= numOfDeliimiter; i++) {
        let index = len - delimiterPoint * i;
        let previousTxt = value.slice(0, index);
        let behindTxt = value.slice(index);
        value = previousTxt + delimiter + behindTxt;
      }
      this.detailInfoDisp.amount = value;
    },

    // 日付入力処理
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.retentionDuedateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.retentionDuedateCal = null;
      }
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  updated() {},
  watch: {
    isShow(newVal) {
      if (newVal) {
        this.init();
      }
    },
    formattedAmount(newVal) {
      this.detailInfoDisp.amount = newVal;
    },
    detailInfo(newVal) {
      this.detailInfo = newVal;
    },
    deep: true,
    retentionDuedateCal(newVal) {
      this.detailInfoDisp.retentionDuedate = this.formatDate(newVal);
    },
  },
};
</script>

<style>
@import "../css/style.css";
</style>

<style lang="scss" scoped>
.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}
</style>
